.play-list-item {
  gap: 1em;

  &-image {
    &::before {
      // counter-increment: section;
      //content: counter(section);
      position: absolute;
      background: #fff;
      width: 31.6px;
      height: 31.6px;
      font-family: SFProDisplay-Regular;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.87);
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 7px;

      // top: 2.24em;
      @media only screen and (max-width: 767px) {
        width: 18.9px;
        height: 18.9px;
        font-size: 11px;
        letter-spacing: -0.13px;
        color: #000;
        // top: 2.24em;
      }
    }

    img {
      @media only screen and (max-width: 767px) {
        width: 115px;
        height: 70px;
        font-size: 11px;
        letter-spacing: -0.13px;
        color: #000;
      }
    }
  }

  .play-list-info-drag {
    display: inline-flex;
    align-items: center;
    // margin-right: 0.5em;
    // cursor: move;
    -webkit-user-drag: auto;
    -khtml-user-drag: auto;
    -moz-user-drag: auto;
    -o-user-drag: auto;
    user-drag: auto;
    img {
      width: 24px;
      height: 24px;
    }
    @media only screen and (max-width: 767px) {
      margin-right: 0;
    }
  }

  &-class-info {
    &.end {
      flex-basis: 10%;

      justify-content: flex-end;
      align-items: flex-start;

      @media only screen and (max-width: 767px) {
        align-items: flex-end;
        margin-right: 0;
        padding-bottom: 1em;
      }
    }

    &-icon {
      .play-list-info-btn-container {
        display: inline-flex;
        width: 40px;
        height: 40px;
        background-color: var(--main-primary-color);
        border-radius: 100%;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.disable {
          background-color: #ededed;

          &:hover {
            background-color: #ededed;
          }
        }

        &:hover {
          background-color: rgb(37, 154, 127);
        }
      }
    }

    &-not-available {
      margin-top: 1em;
      background-color: #d32f2f;
      display: block;
      width: 139px;
      color: #fff;
      font-family: SFProDisplay-Regular;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      line-height: 2em;
      text-align: center;
    }

    h3 {
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &.opacity {
    opacity: 0;
  }
}

.player-video {
  width: 147.2px;
  height: 82.9px;
  margin: 0.5px 57.8px 0 0;
  padding: 0 0.2px 0.3px 0;
  // opacity: 0.5;
  border-radius: 4px;
  // background-color: #000;
}
.player-video-active {
  width: 192.3px;
  height: 108.2px;
  margin: 0.5px 57.8px 0 0;
  padding: 0 0.2px 0.3px 0;
  // opacity: 0.5;
  border-radius: 4px;
  // background-color: #000;
}
.player-video-inactive {
  width: 192.3px;
  height: 108.2px;
  margin: 0.5px 57.8px 0 0;
  padding: 0 0.2px 0.3px 0;
  opacity: 0.5;
  border-radius: 4px;
  // background-color: #000;
}
.rectangle-playerlist-crossicon {
  width: 32px;
  height: 32px;
  background-color: var(--main-primary-color);
  border-radius: 50%;
}
.rectangle-playerlist-crossicon img {
  padding: 1px 5px 5px 8px;
}
.icon-rectangle {
  font-size: 24px;
  padding: 1px 2px 5px 8px;
}
.play-list-info .play-list-item-image::before {
  counter-increment: section;
  content: counter(section);
  position: absolute;
  background: #fff;
  z-index: 999;
  width: 31.6px;
  height: 31.6px;
  font-family: SFProDisplay-Regular;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 0px;
}

.A-short-descripton-o {
  padding-top: 21px;
  font-family: SFProDisplay;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #8e8e93;
}
.right-bar {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.right-bar:hover {
  overflow-y: scroll;
}

// .mt20 {
//   margin-right: 20%;
// }

.sp-inblock {
  display: inline-block;
  width: 55%;
  cursor: pointer;
}
.left-title {
  position: absolute;
  color: #000;
}
.playlist-selected {
  opacity: 1;
}
.playlist-unselected {
  opacity: 0.5;
}
.left-title {
  font-family: SFProDisplay-Semibold;
  font-size: 17px;
  font-weight: 600;
  color: #000;
}
.play-list-item-class-info-time {
  font-family: SFProDisplay;
  font-size: 13px;
  font-weight: normal;
  color: #8e8e93;
}
.play-list-item-class-info-heading {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
.duration-text {
  font-family: SFProDisplay-Regular;
}
.play-list-info-heading-title {
  flex-basis: 70%;
}
.play-list-info-heading-controls {
  flex-basis: 30%;
}
.play-list-info-heading-icon .right-arrow {
  margin-left: -2px;
}
.rectangle-playerlist-crossicon {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .sp-inblock {
    width: 130px;
  }
  .left-title {
    font-weight: 400;
  }
  .play-list-player {
    display: none !important;
  }
  video,
  img,
  .video-js {
    border-radius: 0px;
  }
  .play-list-info-heading {
    margin-bottom: 0px;
  }
  .play-list-item-class-info-time {
    padding-top: 5px;
  }
  .player-item {
    padding-top: 11.2px;
  }
  .right-bar {
    height: 447px !important;
    margin-top: 21px !important;
  }
  .video-player .classes-spinner .MuiCircularProgress-root {
    position: relative !important;
    top: -164px;
  }
  .play-list-info .play-list-item-image::before {
    font-weight: normal !important;
  }
  .play-list-video-player {
    margin-top: -3px !important;
  }
  .video-wrapper {
    border-radius: 0% !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1359px) {
  .right-bar {
    margin-left: 0px !important;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .right-bar {
    margin-top: 27px !important;
  }
  .duration-text {
    font-size: 17px !important;
  }
  .sp-inblock {
    width: 164px;
  }
  .play-list-info-description {
    font-size: 13px;
    padding-top: 12px;
  }
  .play-list-info-heading {
    margin-bottom: 0px;
  }
  .p-t-player {
    padding-top: 24px;
  }
  .play-list-item-class-info-time {
    padding-top: 3px;
  }
  .player-video {
    margin: 6.1px 0.2px 0px 0px;
  }
  .right-bar {
    height: 354px !important;
  }

  .video-player .classes-spinner .MuiCircularProgress-root {
    position: relative !important;
    top: -120px;
    right: 17%;
  }
  .class-side-info .play-list-item .player-item::before {
    width: 24.2px !important;
    height: 24.2px !important;
    margin-top: 6px !important;
    font-size: 20px !important;
  }
}
@media only screen and (min-width: 1360px) and (max-width: 1999px) {
  .right-bar {
    margin-top: -7px !important;
  }
  .play-list-info-description {
    font-family: SFProDisplay-Regular;
    padding-top: 26px;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: #8e8e93;
    width: 74%;
  }
  .play-list-item-class-info-time {
    font-size: 17px;
    font-weight: normal;
    color: #8e8e93 !important;
  }
  .play-list-info-heading {
    margin-bottom: 0;
  }
  .p-t-player {
    padding-top: 26px;
  }

  .class-side-info {
    margin-left: 24px;
  }
  .player-video {
    margin: 6.1px 0.2px 0px 0px;
  }
  .sp-inblock {
    width: 162px;
  }
  .right-bar {
    height: 448.7px !important;
  }

  .player-list-info .play-list-item-image::before {
    width: 24.2px;
    height: 24.2px;
    margin-top: 6px;
  }
  .video-player .classes-spinner .MuiCircularProgress-root {
    position: relative !important;
    right: 17% !important;
    top: 106px;
  }
  .rectangle-playerlist-crossicon {
    width: 40px;
    height: 40px;
  }
  .rectangle-playerlist-crossicon img {
    height: 32px !important;
    padding: 4px 0px 0px 11px !important;
  }
}
@media only screen and (min-width: 2000px) {
  .play-list-info-description {
    width: 889px;
    font-family: SFProDisplay-Regular;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #8e8e93;
    padding-top: 30px;
  }
  .right-bar {
    width: 444px;
  }
  .sp-inblock {
    width: 40%;
  }
  .play-list-info-heading {
    margin-bottom: 0px;
  }
  .p-t-player {
    padding-top: 29px;
  }
  // .video-player {
  //   height: 494.9px;
  // }
  .player-video {
    width: 192.3px;
    height: 108.2px;
    margin: 0 22.7px 5.8px 0;
    padding: 0 0.3px 0.3px 0;
    border-radius: 4px;
  }
  .sp-inblock {
    width: 215px;
  }
  .play-list-item-class-info-time {
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    color: #8e8e93;
  }
  .left-title {
    font-family: SFProDisplay-Semibold;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: -0.2px;
    color: #000;
  }
  .right-bar {
    height: 494.9px !important;
  }

  .video-player .classes-spinner .MuiCircularProgress-root {
    position: relative !important;
    right: 17% !important;
    top: 110px;
  }
  .video-player {
    margin-bottom: 5% !important;
  }
  .rectangle-playerlist-crossicon {
    width: 48px;
    height: 48px;
  }
  .rectangle-playerlist-crossicon img {
    height: 40px !important;
    padding: 4px 0px 0px 13px !important;
  }
  .leaveConfirmation {
    font-family: SFProDisplay-bold !important;
    font-size: 20px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .playerButtonText {
    font-family: SFProDisplay-bold !important;
    font-size: 20px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .play-list-player {
    padding-top: 20px !important;
  }
}

.playlist-crossicon {
  cursor: pointer;
}

.loading {
  pointer-events: none;
}
.classes-spinner {
  position: absolute !important;
  z-index: 999;
}
