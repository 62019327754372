.play-list-info {
  counter-reset: section;
  text-align: left;
  &-details {
    &-actions {
    }
  }
  &-heading {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2em;
    @media only screen and (max-width: 767px) {
      margin-top: 32px;
    }
    &-icon {
      flex-basis: 100%;
      display: inline-flex;
      .right-arrow {
        margin-left: 0;
        cursor: pointer;
        display: block;
        margin-top: 3px;
      }
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
    &-title {
      flex-basis: 50%;
      font-family: SFProDisplay-Semibold;
      font-size: 70px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: -0.8px;
      color: #000;
      @media only screen and (max-width: 767px) {
        font-family: SFProDisplay-Semibold;
        font-size: 22px;
        letter-spacing: -0.2px;
        font-weight: 400 !important;
      }
      @media only screen and (min-width: 768px) {
        font-size: 28px;
        line-height: 1.14;
        letter-spacing: -0.2px;
      }
      @media only screen and (min-width: 1360px) {
        font-size: 40px;
        letter-spacing: -0.8px;
      }
      @media only screen and (min-width: 2000px) {
        font-size: 70px;
        letter-spacing: -1.4px;
      }
    }

    &-controls {
      flex-basis: 50%;
      display: inline-flex;
      justify-content: flex-end;
      gap: 0.5em;
      align-items: center;
      .play-list-info {
        &-heading-text {
          font-family: SFProDisplay-Regular;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: normal;
          color: #8e8e93;
          @media only screen and (max-width: 810px) {
            font-size: 11px;
          }
          @media only screen and (min-width: 820px) and (max-width: 1366px) {
            font-size: 15px;
          }
        }
        &-heading-btn {
          .play-list-info-btn {
            width: 200px;
            height: 48px;

            @media only screen and (min-width: 768px) and (max-width: 1359px) {
              width: 100px;
              height: 32px;
              font-size: 15px !important;
              font-weight: 500;
              .play-list-info-heading-btn {
                .MuiTypography-button {
                  width: 28px;
                  height: 22px;
                  margin: 2px 0.8px 2px 0;
                  font-family: SFProRounded-Medium;
                  font-size: 15px !important;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.47;
                  letter-spacing: normal;
                  text-align: center;
                  color: #fff;
                }
                .MuiTypography-button::after {
                  width: 18px;
                  height: 18px;
                  margin: 6.1px 12.2px 1.9px 0.8px;
                  object-fit: contain;
                }
              }
            }
            //@media only screen and (min-width: 200px) and (max-width:480px) {
          }
        }
      }
      @media only screen and (max-width: 767px) {
        display: none;
        font-size: 20px !important;
      }
    }
  }
  &-description {
    font-family: SFProDisplay-Regular;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: #8e8e93;
    width: 74%;
    @media only screen and (min-width: 810px) and (max-width: 1359px) {
      font-size: 13px;
      line-height: 1.31;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
      letter-spacing: -0.3px;
      font-size: 17px;
      display: block !important;
      padding-top: 18px !important;
    }
  }
  &-actions {
    display: flex;
    gap: 1em;
    @media only screen and (max-width: 767px) {
      margin-top: 18px;
      margin-bottom: 40px;
    }
    @media only screen and (min-width: 768px) {
      margin-top: 19px;
      margin-bottom: 32px;
    }
    @media only screen and (min-width: 1360px) {
      margin-top: 26px;
      margin-bottom: 48px;
    }
    @media only screen and (min-width: 1360px) {
      margin-top: 21px;
      margin-bottom: 40px;
    }
    .play-list-info-btn-container {
      display: inline-flex;
      width: 40px;
      height: 40px;
      background-color: var(--main-primary-color);
      border-radius: 100%;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: rgb(37, 154, 127);
      }
    }
    @media only screen and (max-width: 1359px) {
      width: 100%;
    }
  }
  .play-list-item {
    gap: 1em;
    &-image {
      &::before {
        counter-increment: section;
        content: counter(section);
        position: absolute;
        background: #fff;
        width: 31.6px;
        height: 31.6px;
        font-family: SFProDisplay-Bold !important;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 7px;
        // top: 2.24em;
        @media only screen and (max-width: 767px) {
          width: 18.9px !important;
          height: 18.9px !important;
          font-size: 11px !important;
          letter-spacing: -0.13px;
          color: #000;
          // top: 2.24em;
        }
      }
      img {
        @media only screen and (max-width: 767px) {
          width: 115px;
          height: 64.6px;
          font-size: 11px;
          letter-spacing: -0.13px;
          color: #000;
          border-radius: 4px;
        }
      }
    }
    .play-list-info-drag {
      display: inline-flex;
      align-items: center;
      // margin-right: 0.5em;
      // cursor: move;
      -webkit-user-drag: auto;
      -khtml-user-drag: auto;
      -moz-user-drag: auto;
      -o-user-drag: auto;
      user-drag: auto;
      img {
        width: 24px;
        height: 24px;
      }
      @media only screen and (max-width: 767px) {
        margin-right: 0;
      }
    }
    &-class-info {
      flex-basis: 90%;
      &.end {
        flex-basis: 10%;
        justify-content: flex-end;
        align-items: flex-start;
        margin-right: 2em;
        @media only screen and (max-width: 767px) {
          align-items: flex-end;
          margin-right: 0;
          padding-bottom: 1em;
          margin-right: 14px !important;
        }
      }
      &-icon {
        .play-list-info-btn-container {
          display: inline-flex;
          width: 40px;
          height: 40px;
          background-color: var(--main-primary-color);
          border-radius: 100%;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &.disable {
            background-color: #ededed;
            &:hover {
              background-color: #ededed;
            }
          }
          &:hover {
            background-color: rgb(37, 154, 127);
          }
        }
      }
      &-not-available {
        margin-top: 1em;
        background-color: #d32f2f;
        display: block;
        width: 139px;
        color: #fff;
        font-family: SFProDisplay-Semibold;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        line-height: 2em;
        text-align: center;
        @media only screen and (max-width: 767px) {
          width: 120px;
        }
      }
      // h3 {
      //   @media only screen and (max-width: 425px) {
      //     display: none;
      //   }
      // }
    }
    &.opacity {
      opacity: 0;
    }
  }
  .no-drag {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge*/
    user-select: none;
  }
  &-controls {
    &.for-mobile {
      display: none;
      .play-list-info {
        &-heading-btn {
          .play-list-info-btn {
            @media only screen and (max-width: 767px) {
              .play-list-info-heading-btn {
                .MuiTypography-button {
                  font-size: 20px !important;
                  margin: 0px 0 0 3.5px !important;
                  font-family: SFProDisplay-Bold !important;
                }
              }
            }
            @media only screen and (max-width: 810px) {
              .play-list-info-heading-btn {
                .MuiTypography-button {
                  // margin: 6.1px 12.2px 1.9px 0.8px;
                  margin: 5.5px 0 0 3.5px;
                  object-fit: contain;
                }
              }
            }
          }
        }
        &-heading-text {
          font-family: SFProDisplay-Regular;
          font-size: 17px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.06;
          letter-spacing: normal;
          color: #8e8e93;
          @media only screen and (max-width: 810px) {
            font-size: 11px;
          }
          @media only screen and (max-width: 767px) {
            justify-content: space-between;
            display: inline-flex;
            align-items: center;
            font-size: 17px;
            margin-left: 1em;
            margin-bottom: 20px;
          }
        }
      }

      @media only screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        position: sticky;
        bottom: 0px;
        background-color: #fff;
        z-index: 1000;
        margin-top: 1em;
      }
    }
  }
  .play-list-notification {
    .play-list-alert {
      border-radius: 20px;
      color: #fff;
      .MuiAlert-icon {
        color: #fff;
      }
    }
  }
  .m-t-5 {
    margin-top: 5px !important;
  }
}

// MODAL FOR EDIT
.playlist-modal-root {
  [role="dialog"] {
    overflow: hidden;
    width: 800px;
    min-height: 700px;
    margin: 0;
    padding: 0;
    @media only screen and (max-width: 1024px) {
      width: 600px;
      min-height: 600px;
    }
    @media only screen and (max-width: 767px) {
      min-height: 100%;
      min-width: 100%;
      border-radius: 0;
    }
  }
  .play-list-edit-modal-container {
    margin-top: 2em;
    .play-list-edit-modal-text,
    .play-list-edit-modal-desc {
      width: 45em;
      margin: auto;
      .play-list-edit-text,
      .play-list-edit-desc {
        width: 100%;
        label {
          color: #000;
        }
      }
      input[type="text"],
      textarea {
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.3px;
        font-family: SFProDisplay-Regular;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }
    &.loading {
      pointer-events: none;
    }
    @media only screen and (max-width: 767px) {
      margin: 3em;
    }
  }
}
@media only screen and (max-width: 767px) {
  .playlist-item {
    padding-top: 0px !important;
  }
  .playlist-item-right-side {
    padding-left: 11px !important;
  }
  .play-list-info-controls.for-mobile
    .playlist-mobile-right
    .play-list-info-btn
    .MuiTypography-button {
    font-weight: bold !important;
  }
  .playlist-item-title {
    font-size: 17px !important;
  }
  .play-list-info-btn-container {
    width: 32px !important;
    height: 32px !important;
  }
  .play-list-info-btn-container img {
    width: 20px !important;
    height: 20px !important;
  }
  .edit-modal img {
    height: 16px !important;
    width: 16px !important;
  }
  .play-list-delete {
    align-self: flex-end !important;
  }
  .item-info-title {
    line-height: 22px !important;
  }
  .class-not-available {
    margin-top: 12px !important;
  }
  .item-details-time {
    position: relative;
    top: 5px;
  }
  // Set the delete icon postion in mobile
  .play-list-delete {
    padding-bottom: 0px !important;
    position: relative;
    top: 3px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1359px) {
  .play-list-info-heading-controls
    .play-list-info-heading-btn
    .play-list-info-btn
    .MuiTypography-button {
    width: 100px;
    font-size: 15px !important;
    font-weight: 500;
    padding-left: 12px;
  }
  .MuiButton-label img {
    padding-top: 5px !important;
    height: 24px;
    width: 24px;
  }
  .play-list-delete {
    position: absolute !important;
    right: -3% !important;
    margin-right: 0px !important;
  }

  .loop-font-class {
    font-size: 11px !important;
  }
  .play-list-left-title {
    flex-basis: 60% !important;
  }
  .play-list-right-heading {
    flex-basis: 40% !important;
  }
  .play-list-info-btn-container {
    width: 32px !important;
    height: 32px !important;
  }
  .play-list-info-btn-container img {
    width: 20px !important;
    height: 20px !important;
  }
  .edit-modal img {
    height: 16px !important;
    width: 16px !important;
  }
  .play-list-info .play-list-item-image::before {
    font-size: 13px !important;
    letter-spacing: -0.15px !important;
    height: 24.2px !important;
    width: 24.2px !important;
  }
  .play-icon-btn {
    position: relative;
    top: 9px;
  }
}
@media only screen and (min-width: 1360px) and (max-width: 1999px) {
  .MuiButton-label img {
    height: 28px;
    width: 28px;
    padding-top: 6px;
  }
  .play-list-delete {
    position: absolute;
    right: -2%;
  }
  .loop-font-class {
    font-size: 20px !important;
  }
  .play-list-left-title {
    flex-basis: 60% !important;
  }
  .play-list-right-heading {
    flex-basis: 40% !important;
  }
  .play-list-info-btn-container img {
    width: 24px !important;
    height: 24px !important;
  }
  .edit-modal img {
    height: 20px !important;
    width: 20px !important;
  }
  .play-list-info-heading-btn {
    .MuiTypography-button {
      font-family: SFProDisplay-Semibold !important;
    }
  }
  .play-icon-btn {
    position: relative;
    top: 10px;
    left: 4px;
  }
}
@media only screen and (min-width: 2000px) {
  .MuiButton-label img {
    height: 28px;
    width: 28px;
    padding-top: 6px;
  }
  .play-list-delete {
    position: absolute;
    right: -1%;
  }
  .play-list-info-btn-container {
    width: 48px !important;
    height: 48px !important;
  }
  .play-list-info-btn-container img {
    width: 28px !important;
    height: 28px !important;
  }
  .edit-modal img {
    height: 24px !important;
    width: 24px !important;
  }
  .play-list-info-heading-btn {
    .MuiTypography-button {
      font-family: SFProDisplay-Semibold !important;
    }
  }
  .play-icon-btn {
    position: relative;
    top: 10px;
    left: 4px;
  }
  .playlist-details-description {
    padding-top: 20px !important;
  }
}
.playlist-page-container .play-list-item .play-list-info-drag-safari {
  -webkit-user-drag: element !important;
  -khtml-user-drag: element !important;
  -moz-user-drag: element !important;
  -o-user-drag: element !important;
  user-drag: element !important;
  -webkit-user-select: element !important; /* Chrome/Safari/Opera */
  -khtml-user-select: element !important; /* Konqueror */
  -moz-user-select: element !important; /* Firefox */
  -ms-user-select: element !important; /* Internet Explorer/Edge*/
  user-select: element !important;
  cursor: move;
}
