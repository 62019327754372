.icon-svg {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 5;

  @media screen and (min-width: 1360px) {
    width: 40px;
    height: 40px;
  }
  @media screen and (min-width: 2000px) {
    width: 48px;
    height: 48px;
  }

  &-star {
    pointer-events: none;
    z-index: 1;
    @media screen and (min-width: 1360px) {
    width: 40px;
    height: 40px;
    }
    @media screen and (min-width: 2000px) {
      width: 48px;
      height: 48px;
    }
    // transform: translate(12%, 13%) scale(0.8337, 0.8337);
    // @media screen and (min-width: 1360px) {
    //   transform: translate(21%, 21%);
    // }
    // @media screen and (min-width: 2000px) {
    //   transform: translate(28%, 30%) scale(1.167, 1.167) !important;
    // }
  }
  &-share {
    pointer-events: none;
    z-index: 1;
    // transform: translate(16%, 12%) scale(0.8, 0.8);
    // @media screen and (min-width: 1360px) {
    //   transform: translate(25%, 21%);
    // }
    // @media screen and (min-width: 2000px) {
    //   transform: translate(36%, 33%) scale(1.2, 1.2);
    // }
  }
  &-play-list {
    pointer-events: none;
    z-index: 1;
    // transform: translate(25%, 25%) scale(1, 1);
    // @media screen and (min-width: 1360px) {
    //   transform: translate(36%, 34%) scale(1.2, 1.2);
    // }
    // @media screen and (min-width: 2000px) {
    //   transform: translate(44%, 45%) scale(1.4, 1.4) !important;
    // }
  }
  .delete-icon-svg-play-list {
    pointer-events: none;
    z-index: 1;
    transform: translate(5%, 5%) scale(0.7145, 0.7145);
    @media screen and (min-width: 1360px) {
      transform: translate(13%, 13%) scale(0.857, 0.857);
    }
    @media screen and (min-width: 2000px) {
      transform: translate(21%, 20%) !important;
    }
  }
  .edit-icon-svg-play-list {
    pointer-events: none;
    z-index: 1;
    // transform: translate(16%, 16%) scale(0.8, 0.8);
    // @media screen and (min-width: 1360px) {
    //   transform: translate(26%, 26%) scale(1, 1);
    // }
    // @media screen and (min-width: 2000px) {
    //   transform: translate(35%, 35%) scale(1.2, 1.2);
    // }
  }
}
.icon-svg-disable {
  background-color: #ededed !important;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 5;
  @media screen and (min-width: 1360px) {
    width: 40px;
    height: 40px;
  }
  @media screen and (min-width: 2000px) {
    width: 48px;
    height: 48px;
  }

  .delete-icon-svg-play-list {
    pointer-events: none;
    z-index: 1;
    // transform: translate(5%, 5%) scale(0.7145, 0.7145);
    // @media screen and (min-width: 1360px) {
    //   transform: translate(13%, 13%) scale(0.857, 0.857);
    // }
    // @media screen and (min-width: 2000px) {
    //   transform: translate(21%, 20%) !important;
    // }
  }
}
// .drag-icon-svg-play-list {
//   transform: translate(11%, 10%) scale(1, 1);
//   @media screen and (min-width: 1360px) {
//     transform: translate(24%, 24%) scale(1.2, 1.2);
//   }
//   @media screen and (min-width: 2000px) {
//     transform: translate(36%, 36%) scale(1.4, 1.4);
//   }
// }
// .play-icon-svg-play-list {
//   @media screen and (min-width: 768px) {
//     transform: scale(0.9, 0.9);
//   }
//   @media screen and (min-width: 1360px) {
//     transform: scale(1.1, 1.1);
//   }
// }
.right-arrow {
  .arrow-field {
    @media screen and (max-width: 1359px) {
      transform: translate(0%, 0%) scale(0.75, 0.75) !important;
      position: relative;
      top: -3px;
      right: 5px;
    }
    @media screen and (min-width: 1360px) and (max-width: 1999px) {
      position: relative;
      top: -2px;
    }
  }
  .more-class-arrow {
    @media screen and (max-width: 1359px) {
      //transform: translate(0%, 0%) scale(0.75, 0.75) !important;
      position: relative;
      top: -1px;
      right: 5px;
    }
  }
}
.icon-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  .icon-editor {
    width: 16px;
    height: 16px;
    @media screen and (min-width: 1360px) {
      width: 20px;
      height: 20px;
    }
    @media screen and (min-width: 2000px) {
      width: 24px;
      height: 24px;
    }
  }
}

.icon-back {
    width: 24px;
    height: 24px;
    @media screen and (min-width: 1360px) {
      width: 32px;
      height: 32px;
    }
  }

.icon-playlist-play-svg {
  width: 32px;
  height: 32px;
  @media screen and (min-width: 1360px) {
    width: 48px;
    height: 48px;
  }
  @media screen and (min-width: 2000px) {
    width: 48px;
    height: 48px;
  }
}

.icon-editor-rating{
  width: 32px;
  height: 16px;
  position: absolute;
  right: 0;
   @media screen and (min-width: 1360px) {
    width: 40px;
    height: 32px;
  }
  @media screen and (min-width: 2000px) {
    width: 48px;
    height: 32px;
  }
}
